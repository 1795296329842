import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { ErrorService } from 'src/app/core/error.service';
import { PopupService } from 'src/app/core/popup.service';
import { UserService } from 'src/app/core/user.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AccountSettingsService } from 'src/app/core/account-settings.service';
import { firstValueFrom } from 'rxjs';
import { UserFormComponent } from 'src/app/shared/user-form/user-form.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';

/**
 * Component for all of the account settings.
 */
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingIndicatorComponent,
    UserFormComponent,
  ],
})
export class AccountSettingsComponent implements AfterViewChecked {
  @ViewChild('userFormComponent') userFormComponent!: UserFormComponent;

  /** Settings form. */
  settingsForm: FormGroup;

  /** Whether the account settings is loading. */
  isLoading = false;

  // TODO: Re-enable when addressing notification settings
  // /** Notification settings model. */
  // notificationSettings: NotificationSettings;

  /** The version number for the app. */
  readonly appVersionNumber = environment.appVersionNumber;

  constructor(
    private userService: UserService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private popupService: PopupService,
    private accountSettingsService: AccountSettingsService,
    @Inject(ChangeDetectorRef) private changeDetectorR: ChangeDetectorRef,
  ) {
    this.settingsForm = this.fb.nonNullable.group({
      userForm: this.fb.nonNullable.control(''),
    });
  }

  /**
   * Updates all settings for the user.
   */
  updateSettings(): void {
    this.isLoading = true;
    this.updateUserAccount();
    // TODO: enable when notifications settings are addressed
    // this.updateNotificationSettings();
  }

  /**
   * Checks after the component views and child views.
   */
  ngAfterViewChecked(): void {
    this.changeDetectorR.detectChanges();
  }

  /**
   * Update user account settings data.
   */
  private updateUserAccount(): void {
    const userFormData = this.settingsForm.get('userForm')?.value;
    const { confirmPassword } = userFormData;
    const firstName = userFormData.firstName?.trim();
    const lastName = userFormData.lastName?.trim();
    const timeZone = userFormData.timeZone;
    this.userService
      .updateUserAccount({
        firstName,
        lastName,
        password: confirmPassword,
        timeZone: timeZone,
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.settingsForm.reset();
          this.popupService.notify('Your account settings are updated.');
          this.accountSettingsService.setUser({
            firstName,
            lastName,
            timeZone: timeZone,
            profileImageRithmId:
              this.userService.user.profileImageRithmId || '',
          });
        },
        error: (error: unknown) => {
          this.isLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  // TODO: Re-enable when addressing notification settings
  /**
   * Update notification settings info.
   */
  // private updateNotificationSettings(): void {
  //   this.userService.updateNotificationSettings(this.notificationSettings)
  //     .pipe(first())
  //     .subscribe(() => {
  //       this.isLoading = false;
  //       this.popupService.notify('Your notification settings are updated.');
  //     }, (error: HttpErrorResponse) => {
  //       this.isLoading = false;
  //       this.errorService.displayError(
  //         'Something went wrong on our end and we\'re looking into it. Please try again in a little while.',
  //         error
  //       );
  //     });
  // }

  /**
   * Open the terms and conditions modal.
   */
  async openTerms(): Promise<void> {
    let message = '';
    this.isLoading = true;
    try {
      const termsConditionsText = await firstValueFrom(
        this.userService.getTermsConditions(),
      );
      if (termsConditionsText) {
        message = termsConditionsText;
        this.isLoading = false;
        await this.popupService.terms({
          title: 'Terms and Conditions',
          message,
        });
      }
    } catch (error) {
      this.isLoading = false;
      this.errorService.displayError(
        "Something went wrong on our end and we're looking into it. Please try again in a little while.",
        error,
      );
    }
  }
}
